.NFTs__wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem;
    gap: 1.2rem;
}

.NFTs__container {
    display: flex;
    justify-content: center;
    background: #0b1729;
    flex-flow: column;
    align-items: center;
    padding: 1rem 1rem;
    border: 2px solid hsl(236, 91%, 73%);
    border-radius: 2px;
    width: 150px;
    max-width: 100%;
}

.NFTs__container:hover {
    background: #1a1d40;
    cursor: pointer;
}

.NFTs__loaned-container {
    display: flex;
    justify-content: center;
    background: #100101;
    flex-flow: column;
    align-items: center;
    padding: 1rem 1rem;
    border: 2px solid hsl(0deg 91% 73%);
    border-radius: 2px;
    width: 150px;
    max-width: 100%;
}
.NFTs__loaned-container:hover {
    background: #251212;
    cursor: pointer;
}

.NFTs__NFT-img-container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
}

.NFTs__NFT-img {
    width: 100%;
    max-width: 80vw;
}

.NFTs__NFT-name {
    font-size: 1.5rem;
    text-align: center;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}



.NFTs__active-NFT__wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 1rem;
}

.NFTs__active-NFT__container {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    align-items: center;
    background: #091e22 !important;
    border-radius: 0px !important;
    font-family: 'Share Tech' !important;
    border: 2px solid cyan !important;
    padding: 1rem;
}

@media screen and (max-width: 614px) {
    .NFTs__active-NFT__container {
        width: 100% !important;
    }
}

.NFTs__active-NFT__info-container {
    background: #0b1729;
    min-width: 90%;
    padding: 0.5rem 1rem;
    color: white;
    border: 2px solid #7b84f9;
}

.NFTs__active-NFT__info-title {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

.NFTs__active-NFT__modal-title {
    color: white;
    font-size: 1.7rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
    text-transform: uppercase;
}

.NFTs__active-NFT__keys-container {
    display: flex;
    padding-top: 1rem;
    justify-content: center;
    flex-flow: row wrap;
    gap: 0.2rem;
}

.NFTs__active-NFT__key-img {
    width: 80px;
    max-width: 100%;
    cursor: pointer;
    border: 2px solid transparent;
}

.NFTs__active-NFT__key-container>input[type="radio"]:checked+label .NFTs__active-NFT__key-img {
    border: 2px solid #7b84f9 !important;
    opacity: 80%;
    transition: all ease-in 0.1s;
}

.NFTs__active-NFT__key-container>input[type="radio"]:disabled+label .NFTs__active-NFT__key-img {
    border: 2px solid rgba(128, 128, 128, 0.655);
    opacity: 70%;
    cursor: not-allowed;
}

.utility__snack-bar {
    max-width: 70vw;
}

.NFTs__active-NFT__opened-wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2000;
    background: #171717a3;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NFTs__active-NFT__opened-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    animation-name: glow-display-of-NFT;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    background: #a7abfb;
}

.NFTs__active-NFT__opened-box-img-container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    animation-name: remove-display-of-container;
    animation-duration: 3s;
    animation-fill-mode: forwards;

}


.NFTs__active-NFT__opened-box-img-container>img {
    animation-name: removeDisplayOfNFT;
    animation-duration: 3s;
    width: 100%;
    animation-fill-mode: forwards;
}

@keyframes glow-display-of-NFT {
    0% {
        width: 0px;
        height: 0px;
        min-height: 0px;
        max-width: 80vw;
        border-radius: 0px solid #5c00ff;
    }

    100% {
        width: 400px;
        max-width: 80vw;
        min-height: 500px;
        border: 5px solid #5c00ff;
    }
}

@keyframes remove-display-of-container {
    0% {
        width: 400px;
        max-width: 80vw;
    }

    99% {
        width: 400px;
        max-width: 80vw;
    }

    100% {
        width: 0px;
        max-width: 80vw;
    }
}

@keyframes bring-display-of-container {
    0% {
        width: 0px;
        height: 0px;
    }

    99% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 400px;
        max-width: 80vw;
        height: auto;
    }
}

@keyframes removeDisplayOfNFT {
    from {
        width: 100%;
        transform: rotateY(0deg);
    }

    10% {
        transform: rotateX(20deg);
    }

    20% {
        transform: rotateY(20deg);
    }

    30% {
        transform: rotateX(20deg);
    }

    40% {
        transform: rotateY(20deg);
    }

    50% {
        transform: rotateX(20deg);
    }

    60% {
        transform: rotateY(20deg);
    }

    70% {
        transform: rotateX(20deg);
    }

    80% {
        transform: rotateY(20deg);
    }

    90% {
        transform: rotateX(20deg);
        width: 100%;
    }

    100% {
        transform: rotateY(0deg);
        width: 0px;
    }

    to {
        width: 0px;
    }
}

.NFTs__active-NFT__opened-reward-img-container {
    font-size: 1.2rem;
    background: #a7abfb;
        overflow: hidden;
    animation-name: bring-display-of-container;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.NFTs__active-NFT__opened-reward-img-container>div {
    justify-content: center;
    flex-flow: row wrap;
    display: flex;
}

.NFTs__active-NFT__opened-reward-img-container>div>div img {
    width: 80px;
    max-width: 80vw;
    max-height: 100px;
}