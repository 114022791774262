@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');

body {
  margin: 0;
  font-family: 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f0f0f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Wallet Connect  */
.utility__connect-disconnect-btn {
  background: #000000 !important;
  line-height: normal !important;
  height: auto !important;
  padding: 0.5rem 1rem !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  border: 1px solid rgb(0, 255, 255) !important;
  border-radius: 0px !important;
  color: rgb(0, 255, 255) !important;
  font-family: 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.utility__connect-disconnect-btn:hover {
  background: #0a1e22 !important;
}

.utility__connect-disconnect-btn>.wallet-adapter-button-start-icon {
  height: 1.2rem;
  width: 1.2rem;
}

.wallet-adapter-button {
  font-family: 'Share Tech' !important;
}

.wallet-adapter-modal-wrapper {
  background: #091e22 !important;
  border-radius: 0px !important;
  font-family: 'Share Tech' !important;
  border: 2px solid cyan !important;
}

/* Navbar  */
.navbar {
  background: #00000087;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  flex-flow: row wrap;
}

.navbar__logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__logo {
  width: 4rem;
  max-width: 90vw;
}


/* Preloader  */
.utility__preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.utility__preloader {
  --size: 75px;
  --clr-bg: transparent;
  --clr1: #888ebb;
  --clr2: #8dcacc;
  --clr3: #ee9999;
  --clr4: #eec290;
  --clr5: #88ee95;
  --animation-duration: 5000ms;
  position: relative;
  width: var(--size);
  height: var(--size);
  transform: rotate(45deg);
}

.utility__preloader .spinner-item {
  --item-size: calc(var(--size) / 2.5);
  position: absolute;
  width: var(--item-size);
  height: var(--item-size);
  border: 4px solid var(--clr-spinner);
}

.utility__preloader .spinner-item:nth-child(1) {
  --clr-spinner: var(--clr1);
  top: 0;
  left: 0;
  animation: spinner3A var(--animation-duration) linear infinite;
}

@keyframes spinner3A {

  0%,
  8.33%,
  16.66%,
  100% {
    transform: translate(0%, 0%);
  }

  24.99%,
  33.32%,
  41.65% {
    transform: translate(100%, 0%);
  }

  49.98%,
  58.31%,
  66.64% {
    transform: translate(100%, 100%);
  }

  74.97%,
  83.30%,
  91.63% {
    transform: translate(0%, 100%);
  }
}

.utility__preloader .spinner-item:nth-child(2) {
  --clr-spinner: var(--clr3);
  top: 0;
  left: var(--item-size);
  animation: spinner3B var(--animation-duration) linear infinite;
}

@keyframes spinner3B {

  0%,
  8.33%,
  91.63%,
  100% {
    transform: translate(0%, 0%);
  }

  16.66%,
  24.99%,
  33.32% {
    transform: translate(0%, 100%);
  }

  41.65%,
  49.98%,
  58.31% {
    transform: translate(-100%, 100%);
  }

  66.64%,
  74.97%,
  83.30% {
    transform: translate(-100%, 0%);
  }
}

.utility__preloader .spinner-item:nth-child(3) {
  --clr-spinner: var(--clr5);
  top: var(--item-size);
  left: var(--item-size);
  animation: spinner3C var(--animation-duration) linear infinite;
}

@keyframes spinner3C {

  0%,
  83.30%,
  91.63%,
  100% {
    transform: translate(0, 0);
  }

  8.33%,
  16.66%,
  24.99% {
    transform: translate(-100%, 0);
  }

  33.32%,
  41.65%,
  49.98% {
    transform: translate(-100%, -100%);
  }

  58.31%,
  66.64%,
  74.97% {
    transform: translate(0, -100%);
  }
}

/* Modal  */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  animation-name: Modal-Wrapper;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 200;
}

@keyframes Modal-Wrapper {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

}

.modal-container {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  min-height: calc(100vh - 6rem);
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1050;
  max-width: fit-content;
  background: #091e22 !important;
  border: 2px solid cyan !important;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 60%);
  flex: 1 1;
}
.modal-close-btn {
  padding: 2px 5px;
  display: inline-block;
  cursor: pointer;
  background: #1a1f2e;
  height: 1.2rem;
  border: none;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  line-height: initial;
}

.modal-close-btn:hover {
  background-color: #3c4150;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  height: 100vh;
  width: 100vw;
  transition: opacity linear 150ms;
  animation-name: Modal-Wrapper;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  z-index: 0;
  overflow-x: hidden;
}

/* Other Utility  */
.utility__center-text {
  color: white;
  font-size: 2rem;
  text-align: center;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Buttons  */

.utility__button-cyan-animated {
  position: relative;
  background: #444;
  font-family: 'Share Tech';
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  --clr: #0FF0FC;
  border: none;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;
  padding: 0.7rem 0.7rem;
  transition: 0.2s;
}

.utility__button-cyan-animated:not(:disabled):hover {
  background: var(--clr);
  color: var(--clr);
}


.utility__button-cyan-animated::before {
  content: "";
  position: absolute;
  inset: 2px;
  background: #272822;
}

.utility__button-cyan-animated span {
  position: relative;
  z-index: 1;
}

.utility__button-cyan-animated i {
  position: absolute;
  inset: 0;
  display: block;
}

.utility__button-cyan-animated i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

.utility__button-cyan-animated:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
}

.utility__button-cyan-animated i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

.utility__button-cyan-animated:hover i::after {
  width: 15px;
  left: 80%;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes box {
  0% {
    box-shadow: #27272c;
  }

  50% {
    box-shadow: 0 0 25px var(--clr);
  }

  100% {
    box-shadow: #27272c;
  }
}

/* Form  */
.utility__forms__grey-form .button,
.utility__forms__grey-form .message,
.customSelect,
.utility__forms__grey-form .select,
.utility__forms__grey-form .textarea,
.utility__forms__grey-form .text-input,
.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label,
.utility__forms__grey-form .label {
    padding: 0.75em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    line-height: normal;
    border-radius: 0;
    border: none;
    background: none;
    display: block;
}

.utility__forms__grey-form .label {
    font-weight: bold;
    color: white;
    padding-top: 0;
    padding-left: 0;
    letter-spacing: 0.025em;
    font-size: 1.125em;
    line-height: 1.25;
    position: relative;
    z-index: 100;
}

.required .utility__forms__grey-form .label:after,
.utility__forms__grey-form .required .label:after {
    content: " *";
    color: #E8474C;
    font-weight: normal;
    font-size: 0.75em;
    vertical-align: top;
}

.customSelect,
.utility__forms__grey-form .select,
.utility__forms__grey-form .textarea,
.utility__forms__grey-form .text-input,
.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label {
    font: inherit;
    line-height: normal;
    width: 100%;
    box-sizing: border-box;
    background: #222222;
    color: white;
    position: relative;
}

.customSelect:placeholder,
.utility__forms__grey-form .select:placeholder,
.utility__forms__grey-form .textarea:placeholder,
.utility__forms__grey-form .text-input:placeholder,
.utility__forms__grey-form .option-input+label:placeholder,
.utility__forms__grey-form .checkbox-input+label:placeholder {
    color: white;
}

.customSelect:-webkit-autofill,
.utility__forms__grey-form .select:-webkit-autofill,
.utility__forms__grey-form .textarea:-webkit-autofill,
.utility__forms__grey-form .text-input:-webkit-autofill,
.utility__forms__grey-form .option-input+label:-webkit-autofill,
.utility__forms__grey-form .checkbox-input+label:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #111111 inset;
    -webkit-text-fill-color: white;
    border-top-color: #111111;
    border-left-color: #111111;
    border-right-color: #111111;
}

.customSelect:not(:focus):not(:active).error,
.utility__forms__grey-form .select:not(:focus):not(:active).error,
.utility__forms__grey-form .textarea:not(:focus):not(:active).error,
.utility__forms__grey-form .text-input:not(:focus):not(:active).error,
.utility__forms__grey-form .option-input+label:not(:focus):not(:active).error,
.utility__forms__grey-form .checkbox-input+label:not(:focus):not(:active).error,
.error .customSelect:not(:focus):not(:active),
.error .utility__forms__grey-form .select:not(:focus):not(:active),
.utility__forms__grey-form .error .select:not(:focus):not(:active),
.error .utility__forms__grey-form .textarea:not(:focus):not(:active),
.utility__forms__grey-form .error .textarea:not(:focus):not(:active),
.error .utility__forms__grey-form .text-input:not(:focus):not(:active),
.utility__forms__grey-form .error .text-input:not(:focus):not(:active),
.error .utility__forms__grey-form .option-input+label:not(:focus):not(:active),
.utility__forms__grey-form .error .option-input+label:not(:focus):not(:active),
.error .utility__forms__grey-form .checkbox-input+label:not(:focus):not(:active),
.utility__forms__grey-form .error .checkbox-input+label:not(:focus):not(:active) {
    background-size: 8px 8px;
    background-image: linear-gradient(135deg, rgba(232, 71, 76, 0.5), rgba(232, 71, 76, 0.5) 25%, transparent 25%, transparent 50%, rgba(232, 71, 76, 0.5) 50%, rgba(232, 71, 76, 0.5) 75%, transparent 75%, transparent);
    background-repeat: repeat;
}

.utility__forms__grey-form:not(.has-magic-focus) .customSelect.customSelectFocus,
.utility__forms__grey-form:not(.has-magic-focus) .customSelect:active,
.utility__forms__grey-form:not(.has-magic-focus) .select:active,
.utility__forms__grey-form:not(.has-magic-focus) .textarea:active,
.utility__forms__grey-form:not(.has-magic-focus) .text-input:active,
.utility__forms__grey-form:not(.has-magic-focus) .option-input+label:active,
.utility__forms__grey-form:not(.has-magic-focus) .checkbox-input+label:active,
.utility__forms__grey-form:not(.has-magic-focus) .customSelect:focus,
.utility__forms__grey-form:not(.has-magic-focus) .select:focus,
.utility__forms__grey-form:not(.has-magic-focus) .textarea:focus,
.utility__forms__grey-form:not(.has-magic-focus) .text-input:focus,
.utility__forms__grey-form:not(.has-magic-focus) .option-input+label:focus,
.utility__forms__grey-form:not(.has-magic-focus) .checkbox-input+label:focus {
    background: #4E4E4E;
}

.utility__forms__grey-form .message {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 0.625em;
    color: white;
}

.utility__forms__grey-form .option-input,
.utility__forms__grey-form .checkbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label {
    display: inline-block;
    width: auto;
    color: #4E4E4E;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.utility__forms__grey-form .option-input:focus+label,
.utility__forms__grey-form .checkbox-input:focus+label,
.utility__forms__grey-form .option-input:active+label,
.utility__forms__grey-form .checkbox-input:active+label {
    color: #4E4E4E;
}

.utility__forms__grey-form .option-input:checked+label,
.utility__forms__grey-form .checkbox-input:checked+label {
    color: white;
}

.utility__forms__grey-form .button {
    font: inherit;
    line-height: normal;
    cursor: pointer;
    background: #E8474C;
    color: white;
    font-weight: bold;
    width: auto;
    margin-left: auto;
    font-weight: bold;
    padding-left: 2em;
    padding-right: 2em;
}

.utility__forms__grey-form .green-button {
    background: #47e87a;
}

.utility__forms__grey-form .button:hover,
.utility__forms__grey-form .button:focus,
.utility__forms__grey-form .button:active {
    color: white;
    border-color: white;
}

.utility__forms__grey-form .button:active {
    position: relative;
    top: 1px;
    left: 1px;
}


.utility__forms__grey-form {
    width: calc(100% - 4rem);
    max-width: 80vw;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.utility__forms__grey-form .field {
    width: 100%;
    margin: 0 0 1.5em 0;
}

@media screen and (min-width: 442px) {
    .utility__forms__grey-form .field.half {
        width: calc(50% - 1px);
    }
}

.utility__forms__grey-form .field.last {
    margin-left: auto;
}

.utility__forms__grey-form .textarea {
    max-width: 100%;
}

.utility__forms__grey-form .select {
    text-indent: 0.01px;
    text-overflow: "" !important;
}

.utility__forms__grey-form .select::-ms-expand {
    display: none;
}

.utility__forms__grey-form .checkboxes,
.utility__forms__grey-form .options {
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: hidden;
}

.utility__forms__grey-form .checkbox,
.utility__forms__grey-form .option {
    float: left;
    margin: 1px;
}
